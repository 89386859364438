exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-annual-reports-js": () => import("./../../../src/pages/annual-reports.js" /* webpackChunkName: "component---src-pages-annual-reports-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-csr-js": () => import("./../../../src/pages/csr.js" /* webpackChunkName: "component---src-pages-csr-js" */),
  "component---src-pages-facilities-js": () => import("./../../../src/pages/facilities.js" /* webpackChunkName: "component---src-pages-facilities-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-linkages-js": () => import("./../../../src/pages/linkages.js" /* webpackChunkName: "component---src-pages-linkages-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-our-advantages-js": () => import("./../../../src/pages/our-advantages.js" /* webpackChunkName: "component---src-pages-our-advantages-js" */),
  "component---src-pages-our-company-js": () => import("./../../../src/pages/our-company.js" /* webpackChunkName: "component---src-pages-our-company-js" */),
  "component---src-pages-press-releases-[slug]-js": () => import("./../../../src/pages/press-releases/[slug].js" /* webpackChunkName: "component---src-pages-press-releases-[slug]-js" */),
  "component---src-pages-press-releases-index-js": () => import("./../../../src/pages/press-releases/index.js" /* webpackChunkName: "component---src-pages-press-releases-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-[slug]-js": () => import("./../../../src/pages/products/[slug].js" /* webpackChunkName: "component---src-pages-products-[slug]-js" */),
  "component---src-pages-products-power-semi-conductors-js": () => import("./../../../src/pages/products/power-semi-conductors.js" /* webpackChunkName: "component---src-pages-products-power-semi-conductors-js" */),
  "component---src-pages-products-rf-microwave-modules-js": () => import("./../../../src/pages/products/rf-microwave-modules.js" /* webpackChunkName: "component---src-pages-products-rf-microwave-modules-js" */),
  "component---src-pages-sic-process-capability-js": () => import("./../../../src/pages/sic-process-capability.js" /* webpackChunkName: "component---src-pages-sic-process-capability-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-test-capability-js": () => import("./../../../src/pages/test-capability.js" /* webpackChunkName: "component---src-pages-test-capability-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

